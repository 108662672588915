@import '../../../styles/variable.scss';

.limeButton{
  width: auto !important;;
  .MuiButton-label{
    color: white;
  }
}

a{
  text-decoration: none;
}