.content-wrapper-4{
  background: #312E2B;
  height: auto;
  iframe{
    width: 100%;
  }
  .block{
    .name{
      font-size: 35px;
    }
    .desc{
      font-size: 12px;
    }
  }
  .double-btns{
    a{
      width: 300px;
      height: 55px !important;
    }
  }
  *{
      color: white !important;
  }
}
