.outlet{
    // min-height: 100vh;
}

.example-enter {
    opacity: 0.01;
}
  
.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
  
.example-exit {
    opacity: 1;
}
  
.example-exit.example-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.drop-up .Select-menu-outer {
    top: auto;
    bottom: 100%;
}