.content-wrapper-2{
  background: #312E2B;
  height: auto;
  iframe{
    width: 100%;
    height: 520px;
  }
  .text-1{
    font-size: calc((.9 - 1) * 1.2vw + 1rem);
  }
  .double-btns{
    div{
      width: 300px;
      height: 55px !important;
    }
  }
  *{
      color: white !important;
  }
}
